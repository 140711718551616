<template>
    <div>
        <Header :customstyle="{ background: '#0C1913' }">
            <template slot="title">共识度排行榜</template>
        </Header>
        <div class="content">
            <div class="listcontent">
                <div class="card">
                    <div class="header">
                        <div class="ranking">排名</div>
                        <div class="address">共识度数量</div>
                        <div class="num">32位地址</div>
                    </div>
                    <div>
                        <div class="header" v-for="(item, index) in listdata" :key="index">
                            <div class="ranking">
                                {{index+1}}
                            </div>
                            <div class="num">
                                {{item.cloud_balance}}
                            </div>
                            <div class="address" style="text-align: center;">
                                {{item.address.substr(0,3)+'....'+item.address.substr(-3)}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import Header from "../../components/header.vue";
import {devoteRank} from '@/request/api'
export default {
    components: {
        Header
    },
    data () {
        return {
            listdata: []
        }
    },
    activated () {
        this.getDevoteData()
    },
    methods: {
        getDevoteData () {
            devoteRank().then((res)=> {
                this.listdata = res.data
                console.log(this.listdata)
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .content {
        min-height: 100vh;
        background: rgba(13,25,18,1);
        .listcontent {
            padding: 32px;
            .card{
                width: 686px;
                min-height: 720px;
                border-radius: 24px;
                opacity: 1;
                background: rgba(32,40,35,1);
                padding: 24px;
                .header {
                    display: flex;
                    justify-content: space-between;
                    color: rgba(139,166,149,1);
                    font-size: 28px;
                    font-weight: 400;
                    font-family: "PingFang SC";
                    margin-bottom: 10px;
                    .ranking {
                        width: 56px;
                        display: flex;
                        align-items: center;
                    }
                    .num {
                        display: flex;
                        align-items: center;
                        width: 158px;
                        color: rgba(182,217,195,1);
                        font-size: 28px;
                        font-weight: 400;
                        font-family: "PingFang SC";
                        line-height: 40px;
                    }
                    .address {
                        width: 288px;
                        opacity: 1;
                        color: rgba(139,166,149,1);
                        font-size: 24px;
                        font-weight: 400;
                        font-family: "PingFang SC";
                        white-space: normal;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        -webkit-line-clamp: 3;
                         word-break:break-all;
                    }
                }
            }
        }
        
    }
</style>